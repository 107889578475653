/* -----------------------------------------------------
                   UXPin colors names
 ----------------------------------------------------- */
$mercury: #e7e7e7;
$big-stone: #152935;
$gallery: #ebeaea;
$silver: #c0c0c0;
$endeavour: #0065ab;
$courius-blue: #1c92dd;
$firefly: #0d1e2e;
$aqua-haze: #f7f9fa;
$dove-grey: #6c6c6c;
$alabaster: #f9f9f9;
$denim: #1a85c9;

/* -----------------------------------------------------
                     Action colors
 ----------------------------------------------------- */
$primary: $courius-blue;
$secondary: #f3f2f2;
$ternary: #183059;
$success: #21955f;
$danger: #dc493a;

/* ------------------------------------------------------
                      Role colors
 ------------------------------------------------------ */
$role-0: #7f6370;
$role-1: #407a54;
$role-2: #c55337;
$role-3: #18778b;
$role-4: #8c6605;
$role-5: #da3932;
$role-6: #b7555a;
$role-7: #6a4c93;
$role-8: #ae6126;
$role-9: #2364aa;
$role-10: #710627;

/* -----------------------------------------------------
                        Other
 ------------------------------------------------------ */
$toolbar-background: #ececec;

$autenti-text: #4b4b4b;
$autenti-blue-middle: #0065ab;
$autenti-tropical-blue: #c1e1f5;

$header-dark: #183059;
$header-light: #4AB6FA;

$page-frame: #eee;

$step-type: #d7efe4;
