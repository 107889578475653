@import "colors";
@import "fonts";

$shadow: 2px 2px 3px rgba(0, 0, 0, 0.08);
$radio-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
$page-shadow: rgba(0, 0, 0, 0.16);

/* -----------------------------------------------------------------------------
                                 Content box
 ----------------------------------------------------------------------------- */
.content-box {
  margin: 30px;

  .content-box-empty-content {
    display: none
  }

  .content-box-header {
    display: flex;
    justify-content: space-between;
    margin: 0 4px 20px 4px;
    font-size: 1.2em;
    font-weight: bold;

    .content-box-header-actions {
      display: flex;

      button {
        margin-left: 4px;
      }
    }
  }

  .content-box-content {
    padding: 0;
    background: #fff;
    border: 1px solid darken($aqua-haze, 10%);
    border-radius: 4px;
  }

  &.empty {
    .content-box-header, .content-box-content {
      display: none;
    }

    .content-box-empty-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      font-size: 2em;
      color: darken($aqua-haze, 10%);

      .content-box-empty-info {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        button {
          margin-top: 10px;
          font-size: 0.5em;
        }
      }
    }
  }
}

/* -----------------------------------------------------------------------------
                                 Input
 ----------------------------------------------------------------------------- */
.aut-input {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 28px;
  padding: 4px 8px;
  border: 1px solid transparent;
  border-bottom: 2px solid #e7e7e9;
  border-radius: 4px;
  background: #f4f4f8;
  outline-color: $primary;
  color: $autenti-text;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  transition: all 200ms ease-in;

  &:disabled {
    color: $dove-grey;

    &:hover {
      cursor: not-allowed;
    }
  }

  &::placeholder {
    font-style: italic;
  }

  &:hover:not(:disabled) {
    box-shadow: $shadow;
  }

  &.invalid {
    border-color: $danger;
    border-bottom: 1px solid $danger;
    transition: all 200ms ease-in;
  }
}

.aut-input-select {
  @extend .aut-input;
}

.aut-input-error {
  position: absolute;
  margin-top: 4px;
  height: 18px;
  color: $danger;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

/* -----------------------------------------------------------------------------
                                 Radio
 ----------------------------------------------------------------------------- */
.aut-radio {
  display: flex;
  align-items: center;

  input[type="radio"] {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      display: inline-block;
      padding-left: 26px;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 1px;
        width: 12px;
        height: 12px;
        border: 2px solid #0d1e2e;
        background: #fff;
        border-radius: 14px;
      }
    }

    & + label::after {
      content: '';
      position: absolute;
      top: 6px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-radius: 5px;
      background: #0d1e2e;
      transition: all 0.6s ease;
    }

    &:not(:checked) + label::after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked + label::after {
      opacity: 1;
      transform: scale(1);
    }

    &:hover {
      cursor: pointer;

      & + label::before {
        box-shadow: $shadow;
      }
    }

    &:focus + label::before {
      box-shadow: 0 0 8px $courius-blue;
    }

  }

  &.small {
    input[type="radio"] {
      & + label {
        padding-left: 20px;
        line-height: 12px;

        &::before {
          top: 0;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 12px;
        }
      }

      & + label::after {
        top: 4px;
        left: 4px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
      }
    }
  }
}

/* -----------------------------------------------------------------------------
                                 Checkbox
 ----------------------------------------------------------------------------- */
.aut-checkbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  overflow: hidden;

  input[type="checkbox"] {
    position: absolute;
    margin-left: -30px;
    visibility: hidden;
    transform: scale(0);
    transition: all 200ms ease;

    &+ label::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 12px;
      height: 12px;
      border: 2px solid #152935;
      background: #fff;
    }

    & + label::after {
      content: '\e903';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      background: #152935;
      font-family: "autenticons";
      color: #fff;
      font-size: 12px;
    }

    &:not(:checked) + label::after {
      transform: scale(0);
      transition: all 200ms ease;
    }

    &:checked + label::after {
      transform: scale(1);
      transition: all 200ms ease;
    }
  }
}

/* -----------------------------------------------------------------------------
                                 File
 ----------------------------------------------------------------------------- */
.aut-file-input {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px 9px 16px;
  height: 44px;
  border-radius: 4px;
  background: $secondary;
  overflow: hidden;
  color: #6c6c6c;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 21px;
  font-weight: $semi-bold;
  text-transform: none;
  text-decoration: none;
  transition: all 200ms ease;

  input[type="file"] {
    position: absolute;
    visibility: hidden;
  }

  label {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;

    fa-icon {
      margin-right: 8px;
      margin-left: 0;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #dddcdc;
    color: #152935;

    label {
      cursor: pointer;
    }
  }

  .file-output {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 14px;
    white-space: nowrap;
    color: #6c6c6c;
  }

  &.primary {
    background: $primary;
    color: #fff;

    &:hover {
      background-color: #156ba1;
    }
  }

  &.success {
    background: $success;
    color: #fff;

    &:hover {
      background-color: #155f3d;
    }
  }

  &.danger {
    background: $danger;
    color: #fff;

    &:hover {
      background-color: #8c2f25;
    }
  }

  &.ternary {
    background: $ternary;
    color: #fff;

    &:hover {
      background-color: #091221;
    }
  }

  &.medium {
    padding: 6px 16px 5px 16px;
    height: 32px;

    label {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &.small {
    padding: 3px 8px 3px 8px;
    height: 24px;

    label {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

/* --------------------------------------------------------------------------
                                 Dropdown
 --------------------------------------------------------------------------- */
$dropdown-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);

.aut-dropdown-button {
  position: relative;

  &.active {
    background-color: $ternary;
    color: #fff;
  }

  &.dropdown-primary {
    &.active {
      background-color: $courius-blue;
    }
  }
}

.aut-dropdown-options {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  border-radius: 4px;
  background: #fff;
  box-shadow: $dropdown-shadow;
  z-index: 2;

  .options-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .option {
      position: relative;
      height: 36px;
      background: #fff;
      border-radius: 4px;
      color: #152935;
      overflow: hidden;
      transition: all 200ms ease;

      &.danger {
        color: $danger;
      }

      button {
        display: block;
        margin: 0;
        padding: 4px 8px;
        background: inherit;
        border: none;
        width: 100%;
        height: 100%;
        color: inherit;
        font-size: 14px;
        text-align: left;

        i:first-child:not(:only-child) {
          color: #152935;
          margin-right: 8px;
        }

        i:last-child:not(:only-child) {
          color: #152935;
          margin-left: 8px;
        }

        &.disabled {
          color: #152935;
          background: #abb3c2;

          &:hover {
            cursor: not-allowed;
          }
        }

        &:hover:not(.disabled) {
          background: #f4f4f8;
          cursor: pointer;
          transition: all 200ms ease-in;
        }
      }
    }
  }

  &.dropdown-primary {
    .options-list {
      .option {
        button.disabled {
          background: $autenti-tropical-blue;
        }
      }
    }
  }
}

/* --------------------------------------------------------------------------
                                  Buttons
 --------------------------------------------------------------------------- */
.button {
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 16px 9px 16px;
  height: 40px;
  background: $secondary;
  border: none;
  border-radius: 4px;
  outline-color: $primary;
  color: #6c6c6c;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: $semi-bold;
  line-height: 21px;
  text-transform: none;
  text-decoration: none;
  transition: all 150ms ease-in-out;

  fa-icon:first-child {
    margin-right: 8px;
    margin-left: 0;
  }

  fa-icon:last-child {
    margin-left: 8px;
    margin-right: 0;
  }

  i:first-child:not(:only-child) {
    margin-right: 8px;
    margin-left: 0;
  }

  i:last-child:not(:only-child) {
    margin-left: 8px;
    margin-right: 0;
  }

  &:hover:not(.disabled), &:hover:not(:disabled) {
    cursor: pointer;
    background-color: #dddcdc;
    color: #152935;
  }

  &.primary {
    background: $primary;
    color: #fff;

    &.icon-only {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 40px;
    }

    &:hover:not(.disabled), &:hover:not(:disabled) {
      background-color: #156ba1;
      color: #fff;
    }
  }

  &.success {
    background: $success;
    color: #fff;

    &:hover:not(.disabled), &:hover:not(:disabled) {
      background-color: #155f3d;
    }
  }

  &.danger {
    background: $danger;
    color: #fff;

    &:hover:not(.disabled), &:hover:not(:disabled) {
      background-color: #8c2f25;
      color: #fff;
    }
  }

  &.ternary {
    background: $ternary;
    color: #fff;

    &:hover:not(.disabled), &:hover:not(:disabled) {
      background-color: #091221;
    }
  }

  &.medium {
    padding: 6px 16px 5px 16px;
    height: 32px;
    font-size: 14px;
    line-height: 21px;

    &.icon-only {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 32px;
    }
  }

  &.small {
    padding: 3px 8px 3px 8px;
    height: 24px;
    font-size: 12px;
    line-height: 18px;

    &.icon-only {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 24px;
    }

    span {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &.fill {
    width: 100%;
  }

  &:disabled {
    transition: all 250ms ease-in-out;
    opacity: 0.7;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.disabled {
    transition: all 250ms ease-in-out;
    opacity: 0.7;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.ghost {
    background: none;
    outline: none;

    &.primary {
      color: $primary;

      &:hover:not(.disabled), &:hover:not(:disabled) {
        color: #fff;
        background-color: #156ba1;
      }
    }

    &.success {
      color: $success;

      &:hover:not(.disabled), &:hover:not(:disabled) {
        color: #fff;
        background-color: #155f3d;
      }
    }

    &.danger {
      color: $danger;

      &:hover:not(.disabled), &:hover:not(:disabled) {
        color: #fff;
        background-color: #8c2f25;
      }
    }

    &.ternary {
      color: $ternary;

      &:hover:not(.disabled), &:hover:not(:disabled) {
        color: #fff;
        background-color: #091221;
      }
    }
  }
}
