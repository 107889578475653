/* You can add global styles to this file, and also import other style files */

@import "partials/variables";

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: 1140px;
  overflow-x: auto;
  overflow-y: hidden;
  background: $aqua-haze;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  color: $autenti-text;
  z-index: 0;
}
