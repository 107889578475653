$autenti-font-family: "autenticons" !default;
$autenti-font-path: "/assets/autenti/fonts" !default;

$autenti-font-version: "atf5";

$icon-document-v3: "\e914";
$icon-create-new-document: "\e915";
$icon-accept: "\e916";
$icon-confetti: "\e917";
$icon-reject: "\e918";
$icon-repeat: "\e919";
$icon-add-info: "\e913";
$icon-autenti-template: "\e900";
$icon-user-template: "\e901";
$icon-logout: "\e912";
$icon-path: "\e902";
$icon-calendar: "\e903";
$icon-cancel: "\e904";
$icon-checklist: "\e905";
$icon-checkmark: "\e906";
$icon-chevron-up: "\e907";
$icon-delete: "\e908";
$icon-document: "\e909";
$icon-file-filled: "\e90a";
$icon-formula: "\e90b";
$icon-more-dots: "\e90c";
$icon-more-info: "\e90d";
$icon-multiselect-document: "\e90e";
$icon-remove: "\e90f";
$icon-role: "\e910";
$icon-sender: "\e911";
