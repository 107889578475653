/* ----------------------------------------------------------------------------------------------
                                         Open Sans
 ---------------------------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&amp;subset=latin-ext');

$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

/* ----------------------------------------------------------------------------------------------
                                         Sarala
 ---------------------------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Sarala:400,700&subset=latin-ext');

$regular: 400;
$bold: 700;

/* ----------------------------------------------------------------------------------------------
                                         Autenticons
 ---------------------------------------------------------------------------------------------- */
@import "../assets/autenti/style";
