@import "variables";

@font-face {
  font-family: '#{$autenti-font-family}';
  src:  url('#{$autenti-font-path}/#{$autenti-font-family}.eot?#{$autenti-font-version}');
  src:  url('#{$autenti-font-path}/#{$autenti-font-family}.eot?#{$autenti-font-version}#iefix') format('embedded-opentype'),
    url('#{$autenti-font-path}/#{$autenti-font-family}.ttf?#{$autenti-font-version}') format('truetype'),
    url('#{$autenti-font-path}/#{$autenti-font-family}.woff?#{$autenti-font-version}') format('woff'),
    url('#{$autenti-font-path}/#{$autenti-font-family}.svg?#{$autenti-font-version}##{$autenti-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

i[class*="icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$autenti-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class*="icon-"] {
  &.rotate-90 {
    transform: rotateZ(90deg);
  }

  &.rotate-180 {
    transform: rotateZ(180deg);
  }
}

.icon-document-v3 {
  &:before {
    content: $icon-document-v3;
  }
}
.icon-create-new-document {
  &:before {
    content: $icon-create-new-document;
  }
}
.icon-accept {
  &:before {
    content: $icon-accept;
  }
}
.icon-confetti {
  &:before {
    content: $icon-confetti;
  }
}
.icon-reject {
  &:before {
    content: $icon-reject;
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat;
  }
}
.icon-add-info {
  &:before {
    content: $icon-add-info;
  }
}
.icon-autenti-template {
  &:before {
    content: $icon-autenti-template;
  }
}
.icon-user-template {
  &:before {
    content: $icon-user-template;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-path {
  &:before {
    content: $icon-path;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}
.icon-checklist {
  &:before {
    content: $icon-checklist;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
    color: #fff;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-file-filled {
  &:before {
    content: $icon-file-filled;
  }
}
.icon-formula {
  &:before {
    content: $icon-formula;
  }
}
.icon-more-dots {
  &:before {
    content: $icon-more-dots;
  }
}
.icon-more-info {
  &:before {
    content: $icon-more-info;
  }
}
.icon-multiselect-document {
  &:before {
    content: $icon-multiselect-document;
  }
}
.icon-remove {
  &:before {
    content: $icon-remove;
  }
}
.icon-role {
  &:before {
    content: $icon-role;
  }
}
.icon-sender {
  &:before {
    content: $icon-sender;
  }
}
