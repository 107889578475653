@import "colors";
@import "elements";

$autenti-logo: '../../../../assets/autenti.svg';
$autenti-business-logo: '../../../../assets/autenti-business.svg';

$main-header-height: 64px;

$autologout-header-height: 58px;

$page-width: 794px;
$page-height: 1123px;

$editor-panel-menu-width: 56px;
$editor-panel-content-width: 316px;
$editor-panel-width: $editor-panel-menu-width + $editor-panel-content-width;

/* ----------------------------------------------------------------------------------------------
                                             Page
 ---------------------------------------------------------------------------------------------- */
.scroll-page {
  height: calc(100vh - #{$main-header-height});
  overflow-y: scroll;
}

/* ----------------------------------------------------------------------------------------------
                                           Colors
 ----------------------------------------------------------------------------------------------- */
.danger {
  color: $danger;
}

/* ----------------------------------------------------------------------------------------------
                                            Roles
 ---------------------------------------------------------------------------------------------- */
.role-0 {
  background: $role-0;
  color: #fff;
}

.role-color-0 {
  color: $role-0;
}

.role-1 {
  background: $role-1;
  color: #fff;
}

.role-color-1 {
  color: $role-1;
}

.role-2 {
  background: $role-2;
  color: #fff;
}

.role-color-2 {
  color: $role-2;
}

.role-3 {
  background: $role-3;
  color: #fff;
}

.role-color-3 {
  color: $role-3;
}

.role-4 {
  background: $role-4;
  color: #fff;
}

.role-color-4 {
  color: $role-4;
}

.role-5 {
  background: $role-5;
  color: #fff;
}

.role-color-5 {
  color: $role-5;
}

.role-6 {
  background: $role-6;
  color: #fff;
}

.role-color-6 {
  color: $role-6;
}

.role-7 {
  background: $role-7;
  color: #fff;
}

.role-color-7 {
  color: $role-7;
}

.role-8 {
  background: $role-8;
  color: #fff;
}

.role-color-8 {
  color: $role-8;
}

.role-9 {
  background: $role-9;
  color: #fff;
}

.role-color-9 {
  color: $role-9;
}

.role-10 {
  background: $role-10;
  color: #fff;
}

.role-color-10 {
  color: $role-10;
}
/* ----------------------------------------------------------------------------------------------
                                         Statuses
 ----------------------------------------------------------------------------------------------- */

.status-send, .status-sent {
  color: $courius-blue;
}

.status-error, .status-invalid {
  color: $danger;
}

/* ---------------------------------------------------------------------------------------------
                                        SweetAlert 2 hack
 ---------------------------------------------------------------------------------------------- */

div.swal2-actions > button.swal2-confirm.swal2-styled {
  outline: none;
  box-shadow: none;
}

/* ---------------------------------------------------------------------------------------------
                                            Mixins
 ---------------------------------------------------------------------------------------------- */

@mixin arrow-right($width, $color) {
  width: 0;
  height: 0;
  border-top: $width solid transparent;
  border-bottom: $width solid transparent;
  border-left: $width solid $color;
}

@mixin list-buster {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* ---------------------------------------------------------------------------------------------
                                        Animations
 --------------------------------------------------------------------------------------------- */

$cb-timing-standard: cubic-bezier(.35, 0, .25, 1);

$initial-opacity: 0.1;
$final-opacity: 1;
$duration: 2s;

.animated-arrows {
  .arrow {
    opacity: $initial-opacity;
    animation: pulse $duration ease-in-out infinite;

    &.delay-1 {
      animation-delay: 200ms;
    }

    &.delay-2 {
      animation-delay: 400ms;
    }

    &.delay-3 {
      animation-delay: 600ms;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: $initial-opacity;
  }
  50% {
    opacity: $final-opacity;
  }
  100% {
    opacity: $initial-opacity;
  }
}

/* -----------------------------------------------------------------------------------------
                                  Shake menu animation
 ----------------------------------------------------------------------------------------- */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
